import React, { useEffect, useState } from 'react';
import BasicModal from '../components/BasicModal';
import MarketingFooter from '../components/MarketingFooter';
import Navbar from '../components/NavBar';
import truck from '../images/truck1.png';
import offset from '../images/solar.png';
import rainforest from '../images/rf.jpg';
import { Link, navigate } from 'gatsby';
import MarketingHead from '../components/marketing-meta';

const PricingPage = ()  => {
  const [salesModal, setSalesModal] = useState(false);


  // load the HS script
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);
    script.addEventListener('load', () => {

    })

  }, []);

  // populate a lil form
  useEffect(() => {
    if(window.hbspt && salesModal) {
      window.hbspt.forms.create({
        portalId: '14557468',
        formId: '04f1e797-42b8-4238-90a6-40fd9c2e8496',
        target: '#hubspotForm'
      })
    }
  }, [salesModal])
  
  return (
    <>
    <title>Kodama - About Offsetting</title>
    <MarketingHead />
    <BasicModal title="Let's get in touch" isOpen={salesModal} close={() => setSalesModal(false)}>
        Interested in funding your project through Kodama's open exchange? Get in touch to list your project on the only open, public carbon offset exchange.
        <div id="hubspotForm"/>
    </BasicModal>
    <div className={'bg-hero-image bg-cover p-1 text-white'}>
        <Navbar />  
        <div className="container xl:px-40 mt-10 mb-8 mx-auto">
          <div className="min-h-96 p-2 w-full my-12">
                <p className="text-5xl mb-8 font-bold">Buy your offsets through Kodama</p>
                {/* <div className="px-4 mt-6 mx-2 border-l w-2/3 border-white"> */}
                  <p className="mt-2 text-lg">Kodama's API provides a straight forward way to purchase your offsets.</p>
                  <p className="mt-2 text-lg">We're currently building a portfolio of projects that span the spectrum from reforestation to alternative energy.</p>
                  <button onClick={() => setSalesModal(true)} className="border border-white rounded-md text-white px-6 py-2 mt-4">
                    Get started
                  </button>
                {/* </div> */}
          </div>          
        </div>
    </div>
    <div className="container xl:px-40 mt-10 mb-5 mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 p-4 lg:p-0 my-24 items-center">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 p-4 lg:p-0">
                <h2 className="text-5xl col-span-1 lg:col-span-2 font-bold mb-10 text-primary">Offset API with leading features.</h2>
                <div>
                    <div className="rounded-full p-1 bg-green-100 text-primary w-8 h-8 flex items-center justify-center mb-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                    </div>
                    <h3 className="text-xl font-bold text-primary">
                        API purchasing
                    </h3>
                    <p className="text-gray-700">Use the API to implement offset purchasing straight into your product. The options are open ended, you can offer your customers ways to offset their footprint, or handle it all in-house.</p>
                </div>
                <div>
                    <div className="rounded-full p-1 bg-green-100 text-primary  w-8 h-8 flex items-center justify-center mb-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                        </svg>
                    </div>
                    <h3 className="text-xl mb-1 font-bold text-primary">Project selection</h3>
                    <p className="text-gray-700">
                        Kodama will soon let you select the projects you want to fund with your offsets. We're working to build a diverse portfolio of projects we believe in.
                    </p>
                </div>
                <div>
                    <div className="rounded-full p-1 bg-green-100 text-primary  w-8 h-8 flex items-center justify-center mb-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                    </div>
                    <h3 className="text-xl mb-1 font-bold text-primary">Multi-org support</h3>
                    <p className="text-gray-700">
                        Keep your business operations in check by leveraging our multi-org support. Split reporting across multiple departments or projects.
                    </p>
                </div>
                <div>
                    <div className="rounded-full p-1 bg-green-100 text-primary  w-8 h-8 flex items-center justify-center mb-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                    </div>
                    <h3 className="text-xl mb-1 font-bold text-primary">Reporting</h3>
                    <p className="text-gray-700">Export and share your carbon offsets. Show your customers the impact you're creating, or use reports to track internal emissions goals.</p>
                </div>
            </div>  
            <div className="h-full bg-rainforest-image bg-cover rounded-3xl shadow-lg p-12 flex flex-col justify-center self-end">
                <h4 className="text-3xl font-bold text-white">Kodama is more than carbon</h4>
                <p className="text-xl text-white">The carbon credits Kodama backs go beyond emissions. They support reforestation, habitat protection, and providing opportunity in the developing world.</p>
                <button onClick={() => navigate('/app/register')} className="border border-white rounded-md text-white px-4 py-2 mt-4">
                    I'm ready to offset my emissions
                </button>
            </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 mt-48 gap-6 p-4 lg:p-0 mb-24 items-center">
            <div className="col-span-1 lg:col-span-1">
                <h3 className="text-5xl mb-8 font-bold text-primary">The benefits of offsetting</h3>
                <p className="text-gray-700 text-xl">
                    The benefit of offsetting your emissions goes beyond the environmental impact. It helps your business stay competitive too.
                </p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="border border-gray-100 rounded-lg p-4 shadow-lg">
                    <h3 className="text-xl mb-3 font-bold text-primary">Customers shop with purpose</h3>
                    <p className="text-gray-700 text-sm">
                        87% of customers will purchase from a company because they support a cause the customer supports. 74% will avoid businesses that don't support any social initiatives. Use Kodama to show you support a cause.
                    </p>
                </div>
                <div className="border border-gray-100 rounded-lg p-4 shadow-lg">
                    <h3 className="text-xl mb-3 font-bold text-primary">Causes add value</h3>
                    <p className="text-gray-700 text-sm">
                        Carbon offsets are cost effective. The majority of customers pay more for products that are made buy businesses that support a cause they believe in. Kodama demonstrates added value to your customers.
                    </p>
                </div>
                <div className="border border-gray-100 rounded-lg p-4 shadow-lg">
                    <h3 className="text-xl mb-3 font-bold text-primary">Employees feel better</h3>
                    <p className="text-gray-700 text-sm">
                        75% of millenial employees want to work at a business that supports the same causes they do. 73% say they'd be more likely to accept a job at a company that supports these values, 86% say they'd stay longer.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <MarketingFooter />
    </>
  )
};

export default PricingPage;
